
import {defineComponent} from "vue";
import AddFactoryRoleForm from "@/components/factory/AddFactoryRole.vue"

export default defineComponent({
  name: "AddFactoryRole",
  components: {
    AddFactoryRoleForm
  },
});
