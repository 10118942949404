
import {defineComponent, ref} from "vue";
import {Actions, Api} from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";
import router from "@/router";
import {data as roleData, rules as roleRules, definition} from "@/model/role";
import store from "@/store";
import {getEnum} from "@/core/helpers/enums";
import {castData} from "@/core/data/data_prepare";


export default defineComponent({
  name: "AddEmployeeRoleForm",
  components: {},
  data
  () {
    return {
      factory: {},
      formData: roleData,
      loading: false,
      rules: ref(roleRules),
      employment_type_enum: [],
      work_shift_enum: [],
      wage_type_enum: [],
      isFactoryLoad: false,
      role: {},
      factoryId: this.$route.params.idFactory,

    }
  }
  ,
  mounted: function () {
    this.getCurrentFactory();
  },
  created() {
    getEnum('employmentType').then(({data})=> {
          this.employment_type_enum = data;
      });
    getEnum('workShift').then(({data}) => {
      this.work_shift_enum = data;
    });
    getEnum('wageType').then(({data}) => {
      this.wage_type_enum = data;
    });
  },
  methods: {
    getCurrentFactory() {
      ApiService.get(Api.GET_FACTORY, {id: this.factoryId}).then(response => {
        this.factory = response.data
        this.isFactoryLoad = true;
      })
    },
    submit() {
      let formRef
      if (this.$refs.formRef) {
        formRef = ref(this.$refs.formRef);
      } else return;
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.formData.id_factory = this.factoryId.toString();
          let data = castData(this.formData, definition);
          console.log(data);
          this.loading = false;
          store
            .dispatch(Actions.ADD_ROLE, data)
            .then((data) => {
              console.debug(data)
              router.push({
                name: "factoryRole",
                params: {idFactory: this.factoryId}
              });
            });
        }
      });
    }
  }

});
