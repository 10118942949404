const rules = {
    id_isco: [
        {
            required: true,
            message: "ISOC ID je povinné",
            trigger: "change",
        },
    ],
    name_isco: [
        {
            required: true,
            message: "ISCO název je povinné",
            trigger: "change",
        },
    ],
    name_kkov: [
        {
            required: true,
            message: "Název KKOV je povinné",
            trigger: "change",
        },
    ],
    name_nace: [
        {
            required: true,
            message: "NACE je povinné",
            trigger: "change",
        },
    ],
    title: [
        {
            required: true,
            message: "Název pozice je povinný",
            trigger: "change",
        },
    ],
    wage: [
        {
            required: true,
            message: "Mzda zaměstnance je povinné",
            trigger: "change",
        },
    ],
    wage_type_id: [
        {
            required: true,
            message: "Druh mzdy je povinné",
            trigger: "change",
        },
    ],
    employment_type_id: [
        {
            required: true,
            message: "Druh pracovního poměru je povinné",
            trigger: "change",
        },
    ],
    shift_type_id: [
        {
            required: true,
            message: "Směnost je povinné",
            trigger: "change",
        },
    ],

};

const data = {
    id: 0,
    id_factory: "0",
    id_isco: 0,
    name_isco: "",
    name_kkov: "",
    name_nace: "",
    title: "",
    wage: "",
    wage_type_id: "",
    employment_type_id: "",
    shift_type_id: "",
}

export interface IRole {
    id: number,
    id_factory: number,
    id_isco: number,
    name_isco: string,
    name_kkov: string,
    name_nace: number,
    title: number,
    wage: number,
    wage_type_id: number,
    employment_type_id: number,
    shift_type_id: number,
}

export const definition = {
    id: 'number',
    id_factory: 'number',
    id_isco: 'number',
    name_isco: 'string',
    name_kkov: 'string',
    name_nace: 'string',
    title: 'string',
    wage: 'number',
    wage_type_id: 'number',
    employment_type_id: 'number',
    shift_type_id: 'number',
}

export {rules, data}

